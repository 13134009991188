import { Master } from "../constants";
export const master_data_saga = (data) => {
    return ({
        type: Master.GET_MASTER_DATA_SAGA,
        payload: data
    });
};
export const get_closest_cities = (data) => {
    return ({
        type: Master.GET_CLOSEST_CITIES,
        payload: data
    });
};
export const set_closest_cities = (data) => {
    return ({
        type: Master.SET_CLOSEST_CITIES,
        payload: data
    });
};
export const get_closest_masters_posts = (data) => {
    return ({
        type: Master.GET_CLOSEST_MASTERS_POSTS,
        payload: data
    });
};
export const set_closest_masters_posts = (data) => {
    return ({
        type: Master.SET_CLOSEST_MASTERS_POSTS,
        payload: data
    });
};
export const add_go_home_price = (data) => {
    return ({
        type: Master.ADD_GO_HOME_PRICE,
        payload: data
    });
};
export const master_data_act = (data) => {
    return ({
        type: Master.GET_MASTER_DATA,
        payload: data
    });
};
export const service_loader = () => {
    return ({
        type: Master.SERVICE_LOADER,
    });
};
export const post_files_act = (data) => {
    return ({
        type: Master.POST_FILES,
        payload: data
    });
};
export const post_files_saga_act = (data) => {
    return ({
        type: Master.POST_FILES_SAGA,
        payload: data
    });
};
export const review_saga_act = (data) => {
    return ({
        type: Master.REVIEW_SAGA,
        payload: data
    });
};
export const review_list_loading = () => {
    return ({
        type: Master.REVIEW_LOADING,
    });
};
export const review_act = (data) => {
    return ({
        type: Master.REVIEW,
        payload: data
    });
};
export const master_filter_act = (data) => {
    return ({
        type: Master.MASTER_FILTER,
        payload: data
    });
};
export const close_modal = () => {
    return ({
        type: Master.CLOSE_MODAL
    });
};
export const open_modal = () => {
    return ({
        type: Master.OPEN_MODAL
    });
};
export const master_filter_saga = (data) => {
    return ({
        type: Master.MASTER_FILTER_SAGA,
        payload: data
    });
};
export const master_params = (data) => {
    return ({
        type: Master.MASTER_PARAMS,
        payload: data
    });
};
export const comment_master = (data) => {
    return ({
        type: Master.COMMENT,
        payload: data
    });
};
export const master_add_data = (data) => {
    return ({
        type: Master.ADD_DATA,
        payload: data
    });
};
export const master_schedule = (data) => {
    return ({
        type: Master.GET_MASTER_SCHEDULE,
        payload: data
    });
};
export const available_days = (data) => {
    return ({
        type: Master.AVAILABLE_DAYS,
        payload: data
    });
};
export const available_days_saga = (data) => {
    return ({
        type: Master.AVAILABLE_DAYS_SAGA,
        payload: data
    });
};
export const master_schedule_saga = (data) => {
    return ({
        type: Master.GET_MASTER_SCHEDULE_SAGA,
        payload: data
    });
};
export const master_schedule_loading = (data) => {
    return ({
        type: Master.GET_MASTER_SCHEDULE_LOADER,
        payload: data
    });
};
export const delete_master_schedule = () => {
    return ({
        type: Master.DELETE_MASTER_SCHEDULE,
    });
};
export const master_schedule_select = (data) => {
    return ({
        type: Master.SELECT_SCHEDULE,
        payload: data
    });
};
export const master_address_select = (data) => {
    return ({
        type: Master.SELECT_ADDRESS,
        payload: data
    });
};
export const card_select = (data) => {
    return ({
        type: Master.SELECT_CARD,
        payload: data
    });
};
export const create_order = (data) => {
    return ({
        type: Master.CREATE_ORDER,
        payload: data,
    });
};

export const create_order_saga = (data) => {
    return ({
        type: Master.CREATE_ORDER_SAGA,
        payload: data
    });
};

export const send_order_code_saga = (data) => {
    return ({
        type: Master.SEND_ORDER_CODE_SAGA,
        payload: data
    });
};

export const create_order_loading = () => {
    return ({
        type: Master.CREATE_ORDER_LOADING,
    });
}



